<template>
  <lf-card class="max-w-screen-md py-6 mb-8">
    <template v-slot>
      <loader :isLoading="isSubmitting" />
      <form @submit.prevent="onSubmit">
        <div class="pt-2 space-y-5">
          <lf-switch
            name="is_enforcing_password_ttl"
            v-model="client.is_enforcing_password_ttl"
            :disabled="isFundingAdvisor || isClientFundingAdvisor"
          >
            <lf-h3>
              {{
                $t("ORGANIZATION.PASSWORD_SETTINGS.PASSWORD_EXPIRATION_TOGLE")
              }}
            </lf-h3>
            <span class="mt-2">
              {{ $t("ORGANIZATION.PASSWORD_SETTINGS.TOGGLE_SUBTITLE") }}
            </span>
          </lf-switch>
          <lf-input
            v-if="client.is_enforcing_password_ttl"
            :placeholder="
              $t(
                'ORGANIZATION.PASSWORD_SETTINGS.PASSWORD_EXPIRATION_PLACHOLDER'
              )
            "
            :value="client.password_ttl_days"
            name="password_ttl_days"
            type="text"
            class="pt-3"
          />
        </div>
        <div class="mt-5 space-y-5">
          <div
            class="flex justify-end items-center min-w-full border-t pt-6 pr-5"
          >
            <primary-button
              type="submit"
              :disabled="
                isSubmitting || isFundingAdvisor || isClientFundingAdvisor
              "
            >
              {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
            </primary-button>
          </div>
        </div>
      </form>
    </template>
  </lf-card>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { useAuth } from "@/hooks/auth";
import type { IClient } from "@/models/clients";

const { getters } = useStore();
const { isFundingAdvisor, isClientFundingAdvisor } = useAuth();

const client = computed<IClient>(() => getters["clients/active"]);
const initialValues = reactive({
  data: {
    is_enforcing_password_ttl: client.value.is_enforcing_password_ttl,
    password_ttl_days: client.value.password_ttl_days ?? ""
  }
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});

const onSubmit = handleSubmit(async (values, actions) => {
  // errors are handled in dispatchAction
  await dispatchAction(values, actions, "clients/setPasswordExpiry");
});
</script>
